
































/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import Community from '@/models/graphql/Community';
import MessageButtonPage from '@/views/guest/MessageButtonPage.vue';
import SendToEmailPage from '@/components/auth/SendToEmailPage.vue';
import FindAccountCompleteForm from '@/views/guest/findAccount/FindAccountCompleteForm.vue';
import MessagePage from '@/views/guest/MessagePage.vue';
import IllustrationType from '@/utils/enums/IllustrationType';

const accountStore = namespace('AccountStore');

@Component({
  components: {
    MessagePage,
    FindAccountCompleteForm,
    SendToEmailPage,
    MessageButtonPage,
  },
})
export default class WelcomePage extends Vue {
  @Getter
  community!: Community;

  @Prop({
    required: true,
    default: '',
  })
  token!: string;

  @accountStore.Action
  private checkActivatability!: (
      payload: {
        token: string;
        email: string | null;
        password: string | null;
      },
  ) => Promise<Record<string, boolean>>;

  @accountStore.Action
  private triggerWelcome!: (payload: {
    communityCode: string;
    userId: string;
  }) => void;

  private fetchAccount: {
    validToken: boolean;
    expiredToken: boolean;
    activated: boolean;
    activatable: boolean;
  } | null = null;

  private pageType = '';

  private errorMessage = '';

  private IllustrationType = IllustrationType;

  private tokenUserUid = '';

  private tokenCommunityName = '';

  mounted(): void {
    if (this.token && this.token !== '') {
      const parsedToken = this.parseJwt(this.token);
      let userUid = '';
      let communityName = '';
      if (parsedToken) {
        userUid = parsedToken.u ? parsedToken.u : '';
        communityName = parsedToken.c ? parsedToken.c : '';
      }
      this.tokenUserUid = userUid;
      this.tokenCommunityName = communityName;

      this.checkActivatability({
        token: this.token,
        email: null,
        password: null,
      })
        .then((response) => {
          this.fetchAccount = {
            validToken: response.validToken,
            expiredToken: response.expiredToken,
            activated: response.activated,
            activatable: response.activatable,
          };
        })
        .catch(() => {
          this.pageType = 'expired-link';
        });
    } else {
      this.pageType = 'expired-link';
      this.errorMessage = 'send-to-email.errors.expired-link';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseJwt(token: string): { c: string; e: string; exp: number; iat: number; u: string } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+')
      .replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
      .split('')
      .map((c) => `%${(`00${c.charCodeAt(0)
        .toString(16)}`).slice(-2)}`)
      .join(''));

    return JSON.parse(jsonPayload);
  }

  @Watch('fetchAccount')
  private accountChange(): void {
    if (this.fetchAccount) {
      if (this.fetchAccount.validToken && !this.fetchAccount.activatable) {
        this.pageType = 'change-email';
        this.errorMessage = 'send-to-email.errors.email-used';
        return;
      }
      if (this.fetchAccount.validToken && this.fetchAccount.activatable) {
        if (this.fetchAccount.activated) {
          this.$router.push({
            name: 'login',
            params: { errorMessage: 'app.login-page.errors.account-active' },
          });
          return;
        }
        this.pageType = 'find-account-complete';
        return;
      }

      if (!this.fetchAccount.validToken) {
        this.pageType = 'expired-link';
        this.errorMessage = 'send-to-email.errors.expired-link';
        return;
      }
    }

    this.pageType = 'find-account-complete';
  }

  private onMessageButtonPageClick(): void {
    switch (this.pageType) {
      case 'find-account-activated':
        this.$router.push({
          name: 'login',
          params: { message: 'app.login-page.message' },
        });
        break;
      case 'find-account-error':
        this.$router.push({
          name: 'register',
          params: { token: this.token },
        });
        this.$router.go(0);
        break;
      default:
        break;
    }
  }

  private onCreatePasswordSuccess(): void {
    this.pageType = 'find-account-activated';
  }

  private onCreatePasswordError(): void {
    this.pageType = 'find-account-error';
  }

  private onEmailChanged(newEmail: string): void {
    this.checkActivatability({
      token: this.token,
      email: newEmail,
      password: null,
    })
      .then(() => {
        this.pageType = 'find-account-verify';
        this.triggerWelcome({
          communityCode: this.tokenCommunityName,
          userId: this.tokenUserUid,
        });
      })
      .catch();
  }
}
